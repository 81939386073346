<template>
    <transition :name="appear ? 'smooth' : ''" :appear="appear">
        <button class="w_button"
                :type="type"
                :class="{'disabled': btnDisabled, loading, 'shadow': shadow && !btnDisabled, red, white, done, orange, purple, grey, dark, transparent, transparent_grey, big, small}"
                @click.stop="callback($event); addPulse($el, $event)"
                @mouseup="actionMouseUp"
        >
            <div class="w_button__text">
                <transition name="button-animation">
                    <w-loading-min v-if="loading" key="loading" class="w_button__loading" :color="loadingColor"/>
                </transition>
                <span :class="{'hidden': loading}">
                <slot>name</slot>
            </span>
            </div>
        </button>
    </transition>
</template>

<script>
    export default {
        name: "w_button",
        props: {
            appear: {
                type: Boolean,
                default: false
            },
            big: {
                type: Boolean,
                default: false
            },
            btnDisabled: {
                type: Boolean,
                default: false
            },
            dark: {
                type: Boolean,
                default: false
            },
            done: {
                type: Boolean,
                default: false
            },
            grey: {
                type: Boolean,
                default: false
            },
            loading: {
                type: Boolean,
                default: false
            },
            orange: {
                type: Boolean,
                default: false
            },
            purple: {
                type: Boolean,
                default: false
            },
            red: {
                type: Boolean,
                default: false
            },
            shadow: {
                type: Boolean,
                default: false
            },
            small: {
                type: Boolean,
                default: false
            },
            transparent: {
                type: Boolean,
                default: false
            },
            transparent_grey: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'button'
            },
            white: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            loadingColor() {
                if (this.white) return 'green';
                else if (this.grey || this.transparent_grey) return 'dark';
                else return 'white';
            }
        },
        methods: {
            actionMouseUp(e) {
                this.$emit('mouseup', e);
            },
            addPulse(e, event) {
                let addDiv = document.createElement('div'),
                    mValue = e.clientWidth,
                    rect = e.getBoundingClientRect();

                addDiv.style.width = addDiv.style.height = mValue + 'px';
                addDiv.style.left = event.clientX - rect.left - (mValue / 2) + 'px';
                addDiv.style.top = event.clientY - rect.top - (mValue / 2) + 'px';

                addDiv.classList.add('w_button-pulse');
                e.appendChild(addDiv);
                setTimeout(() => e.removeChild(addDiv), 700);
            },
            callback(e) {
                if (!this.loading && !this.btnDisabled) this.$emit('click', e);
            }
        },
        emits: ['click', 'mouseup']
    }
</script>

<style lang="scss">
    .w_button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: var(--w_color_green);
        border-radius: 16px;
        border: none;
        font-family: 'Inter', "Arial", sans-serif;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        color: var(--w_color_white);
        padding: 14px 40px;
        text-align: center;
        transition: color 50ms ease-out, background-color 300ms ease-out, box-shadow 300ms ease-out, opacity 300ms ease-out;
        cursor: pointer;
        overflow: hidden;
        user-select: none;

        &.shadow {
            box-shadow: 0 8px 20px #65D5A466;
        }

        &.red {
            background-color: var(--w_color_red);

            &.shadow {
                box-shadow: 0 8px 20px #FC4A4170;
            }
        }

        &.orange {
            background-color: var(--w_color_orange);
        }

        &.purple {
            background-color: var(--w_color_purple);
        }

        &.white {
            background-color: white;
            color: var(--w_color_green);

            &.shadow {
                box-shadow: 0 8px 20px rgba(0,0,0,0.16);
            }
        }

        &.dark {
            background-color: var(--w_color_dark_fixed);
            color: var(--w_color_white);

            &.shadow {
                box-shadow: 0 8px 20px rgba(0,0,0,0.16);
            }
        }

        &.transparent {
            background-color: transparent !important;
            color: var(--w_color_green);
            box-shadow: inset 0 0 0 1px var(--w_color_green);
        }

        &.transparent_grey {
            background-color: transparent !important;
            color: var(--w_color_dark);
            box-shadow: inset 0 0 0 1px var(--w_color_grey4);

            &::after {
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0);
                transition: background-color 200ms ease;
            }

            &:hover::after {
                background-color: rgba(0, 0, 0, 0.03);
            }
        }

        &.grey {
            background-color: var(--w_color_grey7);
            color: var(--w_color_dark);
            font-family: "Inter", "Arial", sans-serif;

            &:hover {
                background-color: #e2e4e8;
            }

            &.shadow {
                box-shadow: 0 8px 20px rgba(0,0,0,0.16);
            }
        }

        &.white, &.done, &.transparent {
            .w_button__loading .path {
                stroke: hsl(154, 53%, 53%) !important;
            }
        }

        &.red, &.orange, &.purple {
            .w_button__loading .path {
                stroke: hsl(0, 100%, 100%);
            }
        }

        &.done {
            background-color: transparent !important;
            color: var(--w_color_green) !important;
            font-family: 'Inter-SemiBold', "Arial", sans-serif !important;
            box-shadow: inset 0 0 0 1px var(--w_color_green) !important;

            &.grey {
                color: var(--w_color_grey6) !important;
                box-shadow: inset 0 0 0 1px var(--w_color_grey4) !important;
            }
        }

        &.big {
            padding: 18px 40px;
            font-size: 20px;
            line-height: 28px;
        }

        &.small {
            padding: 8px 12px;
            font-size: 14px;
            line-height: 16px;
            border-radius: 8px;

            .w_button__loading {
                transform: scale(0.7);
            }
        }

        &.disabled {
            pointer-events: none;
            background-color: var(--w_color_grey7);
            color: var(--w_color_placeholder);

            &::after {
                content: none;
            }

            &.transparent {
                color: var(--w_color_placeholder);
                box-shadow: inset 0 0 0 1px var(--w_color_grey10);
            }
        }

        &-pulse {
            background-color: black;
            opacity: .1;
            border-radius: 50%;
            animation: pulse 0.7s ease-out;
            transform: scale(0);
            position: absolute;
        }

        &__text {
            display: flex;
            align-items: center;
            justify-content: center;
            max-height: 24px;
            white-space: nowrap;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s;

                &.hidden {
                    opacity: 0;
                }
            }

            svg {
                margin-right: 4px;
            }
        }

        &__loading {
            z-index: 2;

            svg {
                margin: 0 !important;
            }
        }

        .button-animation {
            &-enter-from, &-leave-to {
                opacity: 0;
                transform: scale(.6);
            }

            &-enter-active, &-leave-active {
                transition: opacity 200ms ease, transform 200ms ease;
                position: absolute;
            }
        }
    }

    @keyframes pulse {
        to {
            transform: scale(2);
            opacity: 0;
        }
    }

    @media (max-width: 1440px) and (min-width: 981px) {
        .w_button {
            font-size: 11px;
            line-height: 16px;
            padding: 9px 40px;
            border-radius: 11px;

            &.big {
                padding: 12px 26px;
                font-size: 14px;
                line-height: 18px;
            }

            &.small {
                padding: 5px 8px;
                font-size: 9px;
                line-height: 11px;
                border-radius: 5px;
            }

            &__loading {
                transform: scale(.7);
            }

            &__text {
                max-height: 16px;

                svg {
                    margin-right: 3px;
                }
            }
        }
    }

    @media screen and (max-width: 980px) {
        .w_button {
            padding: 14px 20px;
            border-radius: 12px;

            &.big {
                padding: 16px;
            }

            &__text svg {
                margin-right: 4px;
            }
        }
    }

    [data-theme='dark'] {
        .w_button {
            &.white {
                background-color: white;
                color: var(--w_color_green);
            }

            &.dark {
                background-color: var(--w_color_white);
                color: var(--w_color_dark_fixed);

                &.shadow {
                    box-shadow: 0 8px 20px rgba(0,0,0,0.16);
                }
            }

            &.grey {
                background-color: var(--w_color_grey4);

                &:hover {
                    background-color: var(--w_color_grey5);
                }
            }

            &.white, &.grey {
                .w_button__loading .path {
                    stroke: hsl(154, 53%, 53%);
                }
            }

            &.transparent_grey {
                box-shadow: inset 0 0 0 1px var(--w_color_grey5);

                &:hover::after {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            &.red, &.orange, &.purple {
                .w_button__loading .path {
                    stroke: hsl(0, 100%, 100%);
                }
            }

            &.disabled {
                color: var(--w_color_grey5);
            }
        }
    }
</style>
