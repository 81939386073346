import axios from "axios";
import moment from 'moment';

const getDefaultState = () => {
    return {
        widget_status: false,
        companion_uuid: null,
        chat_id: null,
        chat_new_companion_uuid: null,
        chats_list: [],
        chats_list_all_loaded: false,
        chats_load_limit: 12,
        new_chat: {},
        messages_list: [],
        messages_list_all_loaded: false,
        messages_load_limit: 30,
        last_selected_chat: null,
        unread_messages_status: false,
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        SELECTED_CHAT: state => {
            let chat = state.chats_list.find(chat => chat.id === state.chat_id)

            if (chat) return chat
            else if (state.chat_id === state.new_chat.id) return state.new_chat
            else return {}
        },
        SELECTED_CHAT_USER: (state, getters) => getters.SELECTED_CHAT.users ? getters.SELECTED_CHAT.users[0] : null
    },
    actions: {
        CLOSE_CHAT({commit}) {
            commit("SAVE_MESSAGES_LIST", {
                "type": "update",
                "messages": []
            })
            commit("SAVE_COMPANION_UUID", null)
            commit("SAVE_CHAT_ID", null)
        },
        GET_CHATS_LIST({state, getters, commit}, type) {
            moment.locale(getters.LOCALE.code);

            return new Promise((resolve, reject) => {
                let URL = `${this.$api_url_chat}/chat/list?limit=${state.chats_load_limit}`

                if (type === 'load_up') {
                    let last_chat = state.chats_list[state.chats_list.length - 1]
                    URL += '&last_message_id=' + last_chat.last_message.id
                } else {
                    commit("CHANGE_CHATS_LIST_ALL_LOADED", false)
                }

                axios
                    .get(URL)
                    .then((resp) => {
                        let chats = resp.data.data.chats
                        if (type === 'load_up') commit("CONCAT_CHATS_LIST", chats)
                        else commit("SAVE_CHATS_LIST", chats)

                        if (chats.length < state.chats_load_limit) commit("CHANGE_CHATS_LIST_ALL_LOADED", true)

                        resolve()
                    })
                    .catch(e => reject(e))
            })
        },
        GET_CHAT({state}, id) {
            return new Promise((resolve, reject) => {
                state
                axios
                    .get(`${this.$api_url_chat}/chat/${id}/info`)
                    .then((resp) => resolve(resp.data.data.chat))
                    .catch((error) => reject(error))
            })
        },
        UPDATE_CHAT({state, dispatch}, message) {
            let chatIndex = state.chats_list.findIndex(chat => chat.id === message.chat_id),
                sortChats = () => {
                    state.chats_list = state.chats_list.sort((a, b) => {
                        if (a.last_message.received_at < b.last_message.received_at) return 1
                        else if (a.last_message.received_at > b.last_message.received_at) return -1
                        else return 0
                    })
                };

            if (~chatIndex) {
                state.chats_list[chatIndex]['last_message'] = message
                if ('unread_messages_count' in message) state.chats_list[chatIndex]['unread_messages_count'] = message.unread_messages_count
                sortChats()
            } else {
                dispatch("GET_CHAT", message.chat_id)
                    .then(chat => {
                        state.chats_list.unshift(chat)
                        sortChats()
                    })
            }
        },
        GET_CHAT_MESSAGES({state, getters, commit}, request) {
            let id = `?recipient_uuid=${request.uuid}&`;

            if (request.chat_id) id = `/${request.chat_id}?`;

            let URL = `${this.$api_url_chat}/chat${id}limit=${state.messages_load_limit}`;

            return new Promise((resolve, reject) => {
                let upload = ['load_up', 'load_down', 'reload'].includes(request.type),
                    getMessages = () => {
                        axios
                            .get(URL)
                            .then(async (resp) => {
                                if (request.type === 'load_down') {
                                    let messages = await document.querySelector('.unit_correspondence_messages-content');
                                    if (messages) await messages.scrollBy({top: -60, left: 0});
                                }

                                if (upload) {
                                    await commit("SAVE_MESSAGES_LIST", {
                                        "type": request.type,
                                        "messages": resp.data.data.messages
                                    })
                                } else {
                                    let chat = resp.data.data.chat;

                                    await commit("SAVE_CHAT_ID", chat.id)
                                    await commit("CHANGE_MESSAGES_LIST_ALL_LOADED", false)
                                    await commit("SAVE_MESSAGES_LIST", {
                                        "type": "update",
                                        "messages": resp.data.data.messages
                                    })

                                    let has_chat = await state.chats_list.findIndex(old_chat => old_chat.id === chat.id)
                                    if (!~has_chat) {
                                        if (!chat.last_message) chat['last_message'] = {
                                            "author_uuid": getters.USER.uuid,
                                            "chat_id": chat.id,
                                            "id": null,
                                            "read_at": null,
                                            "received_at": null,
                                            "text": null
                                        }
                                        await commit("SAVE_NEW_CHAT", chat)
                                    }
                                }
                                if ((!request.type || request.type === 'load_up' || request.type === 'reload') && resp.data.data.messages.length < state.messages_load_limit) {
                                    commit("CHANGE_MESSAGES_LIST_ALL_LOADED", true)
                                }

                                await resolve(request.uuid)
                            })
                            .catch(err => {
                                commit("SAVE_COMPANION_UUID", null)
                                commit("SAVE_CHAT_ID", null)
                                commit("SAVE_MESSAGES_LIST", {
                                    "type": "update",
                                    "messages": []
                                })
                                reject(err)
                            })
                    };

                if (request.type === 'load_up') {
                    URL += '&direction=up&last_message_id=' + state.messages_list[state.messages_list.length - 1].id
                } else if (request.type === 'load_down') {
                    URL += '&direction=down&last_message_id=' + state.messages_list[0].id
                }

                getMessages()
            })
        },
        READ_ALL_MESSAGES({commit, dispatch}, chat_id) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.$api_url_chat}/chat/${chat_id}/read-all-messages`)
                    .then(async () => {
                        await commit("UPDATE_READ_AT", {
                            "is_all_messages": true,
                            "read_at": Date.now()
                        })
                        await dispatch("UPDATE_CHAT_READ_AT", {
                            "chat_id": chat_id,
                            "unread_messages_count": 0
                        })
                        await resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        async UPDATE_NEW_MESSAGE({state, commit, rootState}, message) {
            let message_index = state.messages_list.findIndex(msg => msg.client_message_id === message.client_message_id);

            if (rootState.cache.chat_unsent_messages.length > 0) commit("SAVE_UNSENT_MESSAGE", {message});

            if (~message_index) {
                let local_message = state.messages_list[message_index]
                local_message.read_at = message.read_at;
                local_message.id = message.id;

                if (message.image) {
                    local_message.image = Object.assign(local_message.image, message.image);
                    await this.$chat_images.setItem(local_message.image.actual_file_name, local_message.image.base);
                    await this.$chat_images.setItem(local_message.image.preview_file_name, local_message.image.base);
                } else if (message.audio) {
                    local_message.audio = Object.assign({'blob': local_message.audio.blob}, message.audio);
                    await this.$chat_voices.setItem(local_message.audio.actual_file_name, local_message.audio.blob);
                }
            } else commit("SAVE_NEW_MESSAGE", message)
        },
        UPDATE_CHAT_READ_AT({state, getters, commit}, message) {
            let chatIndex = state.chats_list.findIndex(ct => ct.id === message.chat_id);

            if (~chatIndex) {
                if ('read_at' in message) {
                    if (message.is_all_messages) {
                        state.chats_list[chatIndex].last_message.read_at = message.read_at;
                    } else if (message.message_ids.length) {
                        let read_last_message = message.message_ids.includes(state.chats_list[chatIndex].last_message.id);
                        if (read_last_message) state.chats_list[chatIndex].last_message.read_at = message.read_at;
                    }
                }
                if ('unread_messages_count' in message) state.chats_list[chatIndex].unread_messages_count = message.unread_messages_count;

                let count = 0;
                state.chats_list.forEach(chat => count += chat.unread_messages_count);
                commit("UPDATE_UNREAD_MESSAGES_STATUS", count > 0);

                if (message.uuid !== getters.USER.uuid) state.chats_list[chatIndex].users[0].active = true;
            }
        },
        GET_UNREAD_MESSAGES_COUNT({commit}) {
            axios
                .get(`${this.$api_url_chat}/chat/are-there-unread-messages`)
                .then((resp) => commit("UPDATE_UNREAD_MESSAGES_STATUS", resp.data))
        },
        LOAD_MESSAGES_FOR_SCROLL({state, commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${this.$api_url_chat}/messages`, {
                        params: {
                            'chat_id': state.chat_id,
                            'limit': 30,
                            'message_id': id
                        }
                    })
                    .then((resp) => {
                        commit("CHANGE_MESSAGES_LIST_ALL_LOADED", false)
                        commit("SAVE_MESSAGES_LIST", {
                            "type": "update",
                            "messages": resp.data.data.messages
                        })
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        // eslint-disable-next-line no-empty-pattern
        GET_TIME({}, data) {
            let REFERENCE = moment(),
                TODAY = REFERENCE.clone().startOf('day'),
                YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day'),
                A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day'),
                A_YEAR_OLD = REFERENCE.clone().startOf('year');

            function isToday(momentDate) {
                return momentDate.isSame(TODAY, 'd');
            }

            function isYesterday(momentDate) {
                return momentDate.isSame(YESTERDAY, 'd');
            }

            function isWithinAWeek(momentDate) {
                return momentDate.isAfter(A_WEEK_OLD);
            }

            function isThisYear(momentDate) {
                return momentDate.isAfter(A_YEAR_OLD);
            }

            function isLastYear(momentDate) {
                return !isThisYear(momentDate);
            }

            let valid_date = moment.unix(data.date)

            if (isToday(valid_date)) {
                if (data.type === 'time') return valid_date.format('HH:mm')
                else return valid_date.calendar().split(/[\s,]+/)[0]
            } else if (isYesterday(valid_date)) return valid_date.calendar().split(/[\s,]+/)[0]
            else if (isWithinAWeek(valid_date)) return valid_date.format('ddd')
            else if (isThisYear(valid_date)) return valid_date.format('D MMM')
            else if (isLastYear(valid_date)) return valid_date.format('DD.MM.YYYY')
            else return ""
        }
    },
    mutations: {
        CHANGE_WIDGET_STATUS: (state, bool) => state.widget_status = bool,
        SAVE_CHATS_LIST: (state, list) => state.chats_list = list,
        CONCAT_CHATS_LIST: (state, list) => state.chats_list = state.chats_list.concat(list),
        ADD_NEW_CHAT_IN_LIST: (state, chat) => {
            let new_chat_uniq = state.chats_list.findIndex(ct => ct.id === chat.id)
            if (!~new_chat_uniq) {
                state.chats_list.unshift(chat)
                state.new_chat = {}
            }
        },
        SAVE_NEW_CHAT: (state, chat) => {
            state.new_chat = chat
        },
        HIDE_CHATS_FROM_LIST: (state, data) => {
            let indexChat = state.chats_list.findIndex(chat => chat.id === data.id)
            if (~indexChat) state.chats_list[indexChat]['access_status'] = data.status
            else if (data.id === state.new_chat.id) state.new_chat['access_status'] = data.status
        },
        SAVE_MESSAGES_LIST: async (state, data) => {
            if (data.type === "output") state.messages_list = data.messages;
            else {
                let messages = await data.messages.reverse();
                if (data.type === "update" || data.type === "reload") state.messages_list = messages;
                else if (data.type === "load_up") state.messages_list = state.messages_list.concat(messages);
                else if (data.type === "load_down") state.messages_list = messages.concat(state.messages_list);
            }
        },
        DELETE_MESSAGE(state, id) {
            let messageIndex = state.messages_list.findIndex(message => message.client_message_id === id)
            if (~messageIndex) state.messages_list.splice(messageIndex, 1)
        },
        CHANGE_CHATS_LIST_ALL_LOADED: (state, boolean) => state.chats_list_all_loaded = boolean,
        CHANGE_MESSAGES_LIST_ALL_LOADED: (state, boolean) => state.messages_list_all_loaded = boolean,
        SAVE_NEW_MESSAGE: (state, message) => {
            if (!state.messages_list.length) state.messages_list = [message]
            else if (state.messages_list[0].id !== message.id) state.messages_list.unshift(message)
        },
        UPDATE_COMPANION_STATUS(state, data) {
            let chat = state.chats_list.find(chat => data.uuid === chat.users[0].uuid)
            if (chat) chat.users[0].active = data.active
        },
        SAVE_COMPANION_UUID: (state, uuid) => state.companion_uuid = uuid,
        SAVE_CHAT_ID: (state, name) => state.chat_id = name,
        UPDATE_READ_AT: async (state, data) => {
            if (data.is_all_messages) {
                await state.messages_list.forEach(message => message.read_at = data.read_at)
            } else {
                await state.messages_list.forEach(message => {
                    if (data.message_ids.includes(message.id)) message.read_at = data.read_at
                })
            }
        },
        UPDATE_EDITED_MESSAGE(state, data) {
            let msg = state.messages_list.find(m => m.id === data.message_id);
            if (msg) {
                if (data.deleted_at) {
                    msg['deleted_at'] = data.deleted_at
                    msg['text'] = ""
                } else if (data.edited_at) {
                    msg['edited_at'] = data.edited_at
                    msg['text'] = data.text
                }
            }
        },
        UPDATE_UNREAD_MESSAGES_STATUS: (state, boolean) => state.unread_messages_status = boolean,
        SAVE_CHAT_NEW_COMPANION_UUID: (state, uuid) => state.chat_new_companion_uuid = uuid,
        SAVE_LAST_SELECTED_CHAT: (state, data) => state.last_selected_chat = data,
        COMPANION_BECOME_BLOCKED: (state) => {
            let chat = state.chats_list.find(chat => chat.id === state.chat_id);
            if (chat) chat.users[0].block = {reason: ''};
        },
        CLEAR_CHAT: async (state) => {
            if (this.$chat_images) await this.$chat_images.clear();
            if (this.$chat_voices) await this.$chat_voices.clear();
            await Object.assign(state, getDefaultState());
        },
    }
}
