import axios from "axios";
import Echo from "laravel-echo";

const getDefaultState = () => {
    return {
        notifications: [],
        notifications_unread_status: false,
        notifications_limit: 15,
        notifications_panel: false,
        notifications_all_loaded: false,
        notifications_types: [
            'key_comment',
            'order_execution',
            'translator_key_change',
            'developer_key_change '
        ],
        push_messages: [],
        push_supported_types: [
            'push_for_chat',
            'general_cancelled_push'
        ]
    }
}

let uuid = "";

export default {
    state: () => getDefaultState(),
    actions: {
        async LOAD_NOTIFICATIONS({state, commit}, {unread, update, load_up}) {
            if (!state.notifications.length || update || state.notifications_unread_status || !state.notifications_all_loaded) {
                let url = this.$api_url_locals + '/notification/list';
                let last_id;

                if (state.notifications_unread_status || update) commit("REMOVE_NOTIFICATIONS")
                else if ((state.notifications.length > 0) && load_up) last_id = state.notifications[state.notifications.length - 1].id;

                await axios
                    .get(url, {
                        params: {
                            limit: state.notifications_limit,
                            only_unread: Boolean(unread),
                            last_id
                        }
                    })
                    .then(resp => {
                        let ns = resp.data.data.notifications;

                        if (ns.length) commit("SAVE_NOTIFICATIONS", ns);
                        else if (unread) commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", false);

                        if (ns.length < state.notifications_limit) state.notifications_all_loaded = true;
                    })
                    .catch((e) => {
                        throw new Error(e)
                    })
            }
        },
        async LOAD_NOTIFICATIONS_UNREAD_AVAILABLE({commit}) {
            await axios
                .get(this.$api_url_locals + '/notification/unread')
                .then(response => commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", response.data.data.available));
        },
        async READ_NOTIFICATIONS({state, dispatch, commit}, {all, ids}) {
            await axios
                .post(this.$api_url_locals + '/notification/read', {all: Boolean(all), ids})
                .then(() => {
                    if (Boolean(all) === true) {
                        state.notifications.forEach(n => n.read_at = Date.now().toString());
                        commit("CHANGE_NOTIFICATIONS_UNREAD_STATUS", false);
                    } else if (ids.length) {
                        ids.forEach(id => {
                            let item = state.notifications.find(n => n.id == id)
                            if (item) item.read_at = Date.now().toString()
                        })
                        dispatch("LOAD_NOTIFICATIONS_UNREAD_AVAILABLE")
                    }
                })
        },
        async SOCKET_PUSH_CONNECT({state, getters, commit, rootState}) {
            if (window.$echo?.connector) window.$echo.connector.pusher.disconnect();

            uuid = getters.USER.uuid;

            window.Pusher = require('pusher-js');

            let host = 'wola.io'

            window.$echo = await new Echo({
                broadcaster: 'pusher',
                key: 'fakepusherkey',
                secret: 'fakepushersecret',
                cluster: 'eu',
                forceTLS: true,
                encrypted: true,
                wsHost: host,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],

                authEndpoint: this.$api_url + "/broadcast",
                auth: {
                    headers: {
                        Authorization: rootState.auth.token_type + ' ' + rootState.auth.token
                    }
                }
            });

            let connectPusherChannel = () => {
                window.$echo
                    .private(`notifications.${uuid}`)
                    .listen('NewNotificationEvent', (e) => {
                        let uniq_notification,
                            notification_type = e.notification.notification_type;

                        if (rootState.other.development_host) console.info('localit: New push message.', e.notification);

                        if (state.push_supported_types.includes(notification_type)) {
                            if (e.notification.cancellation_uuid) {
                                uniq_notification = state.push_messages.findIndex(notification => {
                                    return !!notification.cancellation_uuid && (notification.cancellation_uuid !== e.notification.cancellation_uuid)
                                })
                            } else {
                                uniq_notification = state.push_messages.findIndex(notification => notification.uuid && notification.uuid === e.notification.uuid);
                            }

                            if (!~uniq_notification) {
                                if (notification_type === 'general_cancelled_push') {
                                    commit("DELETE_PUSH_MESSAGE", e.notification.cancellation_uuid);
                                    commit("SAVE_NOTIFICATIONS_NEW_COUNT", state.notifications_new_count - 1);
                                } else {
                                    commit("SAVE_NEW_PUSH", e.notification);
                                    if (notification_type === 'push_for_chat') commit("UPDATE_UNREAD_MESSAGES_STATUS", true);
                                }
                            }
                        }
                    })
            }

            await window.$echo.connector.pusher.connection.bind('connected', () => console.info('WOLA: System pusher connected'));
            await window.$echo.connector.pusher.connection.bind('disconnected', () => {
                console.info('WOLA: System pusher disconnected');
                connectPusherChannel();
            });

            await connectPusherChannel()
        },
    },
    mutations: {
        SAVE_NOTIFICATIONS: (state, notifications) => state.notifications = state.notifications.concat(notifications),
        REMOVE_NOTIFICATIONS: (state) => state.notifications = [],
        CHANGE_NOTIFICATIONS_UNREAD_STATUS: (state, bool) => state.notifications_unread_status = bool,
        CHANGE_NOTIFICATIONS_PANEL: (state) => {
            state.notifications_panel = !state.notifications_panel
        },
        CLOSE_NOTIFICATIONS_PANEL: (state) => {
            state.notifications_panel = false;
            state.notifications = [];
            state.notifications_all_loaded = false;
        },
        SAVE_NEW_PUSH: (state, push) => {
            state.push_messages.unshift(push)
            // if (state.push_messages.length > 3) state.push_messages.shift();
        },
        DELETE_PUSH_MESSAGE: (state, id) => {
            let pushIndex = state.push_messages.findIndex(push => push.uuid === id)
            if (~pushIndex) state.push_messages.splice(pushIndex, 1)
        },
        DELETE_USER_MESSAGES_NOTICE: (state, user_uuid) => {
            state.push_messages = state.push_messages.filter(push => {
                return (push.notification_type !== 'push_for_chat') || (push.user_uuid !== user_uuid);
            })
        },
        CLEAR_PUSH_MESSAGE: (state) => state.push_messages = [],
        CLEAR_NOTIFICATIONS: (state) => {
            Object.assign(state, getDefaultState());
        },
    },
}
