<template>
    <figure class="w_img icon"
            :class="{'avatar_stub': photo, 'radius-27': !round}"
            :title="title || alt"
            @click="$emit('click')"
    >
        <template v-if="show">
            <template v-if="loading && !no_loading">
                <div v-if="photo" class="w_img-photo avatar_stub"/>
                <w-loading-min v-else key="loading" class="w_img__loading" color="green"/>
            </template>
            <transition v-else-if="!empty && src" :name="animate ? 'show-image' : null" appear>
                <img key="image"
                     ref="w-image"
                     :src="src"
                     :alt="image_alt"
                     @error="empty = true"
                     @load="$emit('load')"
                />
            </transition>
            <div v-else-if="empty && photo" class="w_img-empty avatar_stub"></div>
        </template>
    </figure>
</template>

<script>
    const cache = new Map()

    export default {
        name: "w_img",
        props: {
            alt: {
                type: String
            },
            always: {
                type: Boolean,
                default: false
            },
            animate: {
                type: Boolean,
                default: false
            },
            auth_need: {
                type: Boolean,
                default: false
            },
            folderName: {
                type: String,
            },
            imageName: {
                type: String,
            },
            no_loading: {
                type: Boolean,
                default: false
            },
            once: {
                type: Boolean,
                default: false
            },
            photo: {
                type: Boolean,
                default: false
            },
            round: {
                type: Boolean,
                default: true
            },
            srcPath: {
                type: String,
            },
            title: {
                type: String,
            }
        },
        data() {
            return {
                src: '',
                loading: true,
                show: true,
                empty: false,
                image_alt: null
            }
        },
        computed: {
            filepath() {
                return require(`@/assets/images/${this.folderName}/${this.imageName}`)
            }
        },
        created() {
            this.image_alt = this.alt || this.imageName?.replace('.png', '').replace('.jpg', '').replace('.jpeg', '')
        },
        mounted() {
            if (!this.always && !this.$store.getters.MOBILE) {
                this.observer = new IntersectionObserver((entries) => {
                    this.show = entries[0].isIntersecting;
                    if (this.show && (!this.src || this.loading)) this.loadFile()
                }, {
                    threshold: this.threshold || 0
                });

                this.$nextTick(() => this.observer.observe(this.$el))
            } else this.loadFile()
        },
        methods: {
            async getBase64Image() {
                let url

                try {
                    if (this.srcPath) {
                        url = this.srcPath
                    } else {
                        url = this.filepath
                    }
                } catch (e) {
                    if (e.code !== 'MODULE_NOT_FOUND') throw e
                    return
                }

                if (url) {
                    if (!cache.has(url)) {
                        try {
                            let headers;

                            if (this.auth_need) {
                                headers = {
                                    headers: new Headers({
                                        'Authorization': this.$store.state.auth.token_type + ' ' + this.$store.state.auth.token
                                    })
                                }
                            }

                            await fetch(url, headers)
                                .then(response => response.blob())
                                .then(blob => new Promise((resolve, reject) => {
                                    const reader = new FileReader()
                                    reader.onloadend = () => {
                                        cache.set(url, reader.result)
                                        resolve(reader.result)
                                    }
                                    reader.onerror = reject
                                    reader.readAsDataURL(blob)
                                }))
                        } catch (e) {
                            await cache.delete(url)
                        }
                    }

                    if (cache.has(url)) return await cache.get(url)
                    else throw Error('No image in local cache:', this.srcPath, this.filepath)
                } else {
                    throw Error('No image src')
                }
            },
            loadFile() {
                if (this.once) {
                    this.$emit('onShowImage');
                    this.observer?.disconnect();
                }

                this.getBase64Image()
                    .then(base64 => this.src = base64)
                    .catch(() => this.empty = true)
                    .finally(() => this.loading = false)
            }
        },
        render() {
            try {
                return this.$el;
            } catch (e) {
                throw new Error('w_img.vue can render one and only one child component.');
            }
        },
        emits: ['load', 'click', 'onShowImage'],
        unmounted() {
            this.observer?.disconnect()
        }
    }
</script>

<style lang="scss" scoped>
    .w_img {
        position: relative;
        cursor: default;

        &-empty {
            border-radius: inherit;
        }

        &-empty, &-photo, img {
            width: inherit;
            height: inherit;
            max-width: inherit;
            max-height: inherit;
            user-select: none;
            pointer-events: none;
            object-fit: cover;
        }

        .show-image {
            &-enter-from {
                opacity: 0;
                transform: translateY(80px);
            }

            &-enter-active {
                transition-property: transform, opacity;
                transition-timing-function: ease;
                transition-duration: 400ms;
                transition-delay: 200ms;
            }
        }
    }
</style>
